import { ConditionalRender } from "@shared/components/ConditionalRender";
import NumberFormat from "@shared/components/NumberFormat";
import { NA } from "@shared/constants/app.constant";
import {
  getLoanDocStatusColor,
  getLoanDocStatusLabel,
} from "@shared/helpers/documents.helper";
import { getProgramType } from "@shared/helpers/global.helper";
import { useAppSelector } from "@store/hooks";
import { appSelector, appUserSelector } from "@store/slices/app.slice";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { SystemInfo } from "./SystemInfo";
import clsx from "clsx";
import { isPrequalApplication } from "@shared/helpers/application.helper";
import {
  IPortalApplication,
  ProductLos,
} from "@shared/interfaces/PortalApplicationTypes";
import { isEmailSungageLoanDocsLinkLoading } from "@store/slices/loan.slice";
import InlineLoader from "@shared/components/InlineLoader";

export const LoanDocuments = ({
  requestDetail,
  variant = "default",
  handleEmailLoanDocsLinkForSungae,
}: {
  requestDetail: IPortalApplication | undefined;
  variant?: "default" | "new";
  handleEmailLoanDocsLinkForSungae: (id: string) => void;
}) => {
  const history = useHistory();
  const appSlice = useAppSelector(appSelector);
  let { id } = useParams<{ id: string }>();
  const user = useAppSelector(appUserSelector);
  const emailSungageLoanDocsLinkLoading = useAppSelector(
    isEmailSungageLoanDocsLinkLoading
  );

  return (
    <div>
      <h3 className="h3 text-primary-dark mt-4">Loan Documents</h3>
      <hr className="bg-primary-dark height-2px opacity-1" />
      <Row className="mt-3">
        <Col xs={4} sm={4}>
          <h4 className="font-bold-800">Product</h4>
          <p>
            Product Name:{" "}
            <ConditionalRender
              value={
                requestDetail?.programTypeCode
                  ? getProgramType(requestDetail?.programTypeCode)
                  : undefined
              }
            />
          </p>
          <p>
            {isPrequalApplication(requestDetail)
              ? "Prequalified For"
              : "Amount Financed"}
            : <NumberFormat amount={requestDetail?.loanOffer?.amountFinanced} />
          </p>
        </Col>
        <Col xs={4} sm={4}>
          <SystemInfo requestDetail={requestDetail} />
        </Col>
        <Col xs={4} sm={4}>
          <h4 className="font-bold-800">Doc Status</h4>
          {!requestDetail ? (
            <p>{NA}</p>
          ) : (
            <p
              className={clsx(
                requestDetail && getLoanDocStatusColor(requestDetail)
              )}
            >
              {emailSungageLoanDocsLinkLoading ? (
                <InlineLoader text="Sending..." />
              ) : (
                getLoanDocStatusLabel(requestDetail)
              )}
            </p>
          )}
        </Col>
        <Col xs={12} sm={12}>
          <div className="d-flex justify-content-center mt-3 gap-3">
            {requestDetail &&
              !!requestDetail.portalAccessibility?.issueLoanDocs && (
                <Button
                  onClick={() => history.push(`/issue-docs/${id}`)}
                  disabled={requestDetail.los === ProductLos.SUNGAGE}
                >
                  Issue Loan Docs
                </Button>
              )}

            {requestDetail?.los === ProductLos.SUNGAGE &&
              !!requestDetail.losAppNumber &&
              true && (
                <Button
                  variant="outline-primary"
                  size="sm"
                  type="button"
                  onClick={() =>
                    handleEmailLoanDocsLinkForSungae(
                      requestDetail.losAppNumber || ""
                    )
                  }
                >
                  Email Loan Docs Link
                </Button>
              )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
