import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import isUndefined from "lodash/isUndefined";

type FieldProps = {
  control: any;
  name: any;
  placeholder?: string;
  className?: string;
  defaultValue?: string | number;
  disabled?: boolean;
  list: Array<any>;
  placeholderValue?: string | number;
};

export const UseFormSelect = ({
  control,
  name,
  disabled = false,
  placeholder,
  className,
  defaultValue = "",
  list,
  placeholderValue,
}: FieldProps) => {
  let options = list?.map(function (option, i) {
    return (
      <option key={i} value={option.value}>
        {option.text}
      </option>
    );
  });
  return (
    <Controller
      key={name}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value },
        fieldState: { error, isDirty },
      }) => (
        <>
          <Form.Select
            disabled={disabled}
            key={Math.random().toString()}
            onChange={onChange}
            value={value}
            isInvalid={!!error}
            name={name}
            id={name}
          >
            {!isUndefined(placeholder) && (
              <option value={placeholderValue}>{placeholder}</option>
            )}
            {options}
          </Form.Select>
          {error && (
            <Form.Text
              id="passwordHelpBlock"
              className="text-danger px-2"
              muted
            >
              {error?.message}
            </Form.Text>
          )}
        </>
      )}
    />
  );
};
