import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  removeSpaces,
  unmaskMobile,
  maskMobile,
} from "@shared/helpers/global.helper";
import {
  postRequestAction,
  loanSliceSelector,
  setDuplicateAppsandCurrentAppSubmissionData,
} from "@store/slices/loan.slice";
import { newRequestSchema } from "@shared/schema/request";
import { UserAuthStatus } from "@shared/enums/UserState";
import { useAuthUserContext } from "@contexts/AuthContext";
import { formatDateForDl4Submission } from "@utils/date";
import { EmploymentStatus } from "@shared/enums/EmploymentStatus";
import {
  IApplicantAddressBase,
  ISubmitPortalFullApplication,
  ProductLos,
} from "@shared/interfaces/PortalApplicationTypes";
import useTotalSystemSize from "@hooks/useTotalSystemSize";
import FullApplicationForm from "@shared/components/FullApplicationForm";
import { isSungageLoanProduct } from "@shared/helpers/application.helper";

const Request = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const loanSlice = useAppSelector(loanSliceSelector);
  const { authUserStatus } = useAuthUserContext();

  const methods = useForm({
    resolver: yupResolver(newRequestSchema),
  });

  const { watch, reset } = methods;

  const [
    installationAddressIsDifferent,
    loanType,
    moduleType,
    batteryType,
    batteryExtensionType,
    panelQuantity,
    batteryQuantity,
    batteryExtensionQuantity,
    ssn,
  ] = watch([
    "installationAddressIsDifferent",
    "loanType",
    "moduleType",
    "batteryType",
    "batteryExtensionType",
    "panelQuantity",
    "batteryQuantity",
    "batteryExtensionQuantity",
    "SSN",
  ]);

  const isSungageProduct = isSungageLoanProduct(loanType);

  const employmentStatus = Number(watch("employmentStatus"));
  const coBorrowerEmploymentStatus = Number(
    watch("coBorrowerEmploymentStatus")
  );

  const isPrimaryEmployed =
    employmentStatus === EmploymentStatus.EMPLOYED ||
    (isSungageProduct && employmentStatus === EmploymentStatus.SELF_EMPLOYED);

  const isSecondaryEmployed =
    coBorrowerEmploymentStatus === EmploymentStatus.EMPLOYED ||
    (isSungageProduct &&
      coBorrowerEmploymentStatus === EmploymentStatus.SELF_EMPLOYED);

  const { totalSolarSize, totalBatterySize } = useTotalSystemSize({
    moduleType,
    batteryType,
    batteryExtensionType,
    panelQuantity,
    batteryQuantity,
    batteryExtensionQuantity,
    los: isSungageProduct ? ProductLos.SUNGAGE : ProductLos.DL4,
  });

  const handleOnSubmit = (event: any) => {
    const currentAddressOfPrimaryApplicant: IApplicantAddressBase = {
      ownershipType: 4,
      addressType: 1,
      addressFormat: 5,
      postalCode: event.postalCode,
      addressLine: event.addressLine1,
      city: event.city,
      state: event.state,
      yearsAtResidence: Number(event.yearsAtResidence),
      monthsAtResidence: Number(event.monthsAtResidence),
    };

    const mailingAddressOfPrimaryApplicant: IApplicantAddressBase = {
      ownershipType: 4,
      addressType: 3,
      addressFormat: 5,
      postalCode: event.mailingZipCode,
      addressLine: event.mailingAddressLine1,
      city: event.mailingCity,
      state: event.mailingState,
    };

    const currentAddressOfCoBorrower: IApplicantAddressBase = {
      ownershipType: 4,
      addressType: 1,
      addressFormat: 5,
      postalCode: event.coBorrowerPostalCode,
      addressLine: event.coBorrowerAddressLine1,
      city: event.coBorrowerCity,
      state: event.coBorrowerState,
      yearsAtResidence: Number(event.coBorrowerYearsAtResidence),
      monthsAtResidence: Number(event.coBorrowerMonthsAtResidence),
    };

    const mailingAddressOfCoBorrower: IApplicantAddressBase = {
      ownershipType: 4,
      addressType: 3,
      addressFormat: 5,
      postalCode: event.coBorrowerMailingZipCode,
      addressLine: event.coBorrowerMailingAddressLine,
      city: event.coBorrowerMailingCity,
      state: event.coBorrowerMailingState,
    };

    const hasCoApplicant = event.hasCoApplicant === "Yes" ? true : false;

    const submitPortalAppPayload: ISubmitPortalFullApplication = {
      prequal: false,
      isMultiPrequal: false,
      xSecondSubmission: false,
      appSource: "Flic Frontend",
      hasCoApplicant,
      loanAmount: parseInt(event.loanAmount),
      xprogramtype: Number(event?.loanType),
      panelCost: isSungageProduct && event?.panelCost ? event.panelCost : null,
      panelSize: isSungageProduct ? totalSolarSize : null,
      batteryCost:
        isSungageProduct && event?.batteryCost ? event.batteryCost : null,
      batterySize: isSungageProduct ? totalBatterySize : null,
      moduleType: isSungageProduct ? Number(event.moduleType) : null,
      panelQuantity:
        isSungageProduct && event?.panelQuantity
          ? Number(event.panelQuantity)
          : null,
      batteryType: isSungageProduct ? Number(event.batteryType) : null,
      batteryExtensionType: isSungageProduct
        ? Number(event.batteryExtensionType)
        : null,
      batteryQuantity:
        isSungageProduct && event?.batteryQuantity
          ? Number(event.batteryQuantity)
          : null,
      batteryExtensionQuantity:
        isSungageProduct && event?.batteryExtensionQuantity
          ? Number(event.batteryExtensionQuantity)
          : null,
      isInstallationAddressSame: !event.installationAddressIsDifferent,
      installationAddress: {
        propertyType: Number(event.propertyType),
        addressLine: installationAddressIsDifferent
          ? event.installationAddressLine
          : event.addressLine1, // [xInstallationAddressLine]
        city: installationAddressIsDifferent
          ? event.installationCity
          : event.city, // [xInstallationCity]
        state: installationAddressIsDifferent
          ? event.installationState
          : event.state, // [xInstallationState]
        zipCode: installationAddressIsDifferent
          ? event.installationZipCode
          : event.postalCode, // [xInstallationZipCode]
        installationHomeOwner: Number(event.installationHomeOwner), // [xStatedInstallationHomeOwner]
        installationResidency: Number(event.installationResidency) || 1, // [installationResidency]
        reverseMortgage: isSungageProduct
          ? event.reverseMortgage === "Yes"
          : null,
        mortgagePayment: isSungageProduct
          ? Number(event.mortgagePayment)
          : null,
      },
      productLos: isSungageProduct ? ProductLos.SUNGAGE : ProductLos.DL4,
      primaryApplicant: {
        applicantType: 1,
        lastName: event.lastName,
        firstName: event.firstName,
        SSN: removeSpaces(event.SSN),
        dateOfBirth: formatDateForDl4Submission(event.dateOfBirth),
        email: event.email,
        isUSCitizen: event.isUSCitizen === "Yes",
        isPermanentResident:
          event.isUSCitizen === "Yes"
            ? null
            : event.xIsPermanentResident === "Yes", // Sungage doesn't require this. [xIsPermanentResident]
        mobilePhone: unmaskMobile(event.mobilePhone),
        authorization: event.authorization,
        eSignatureConsent: event.consent === "true",
        disclosureConsent: event.consent === "true",
        myInstallationAddressIsDifferentThanCurrentAddress:
          !!event.installationAddressIsDifferent,
        /** Current Address */
        currentAddress: currentAddressOfPrimaryApplicant,
        /** Mailing Address */
        myMailingAddressIsDifferentThanCurrentAddress:
          !!event?.isMailingAddressDifferent,
        mailingAddress: !!event.isMailingAddressDifferent
          ? mailingAddressOfPrimaryApplicant
          : undefined,
        employment: {
          employmentType: 1, // DL4 specific
          grossIncome: Number(event.grossIncome),
          monthsEmployed: event.monthsEmployed
            ? Number(event.monthsEmployed)
            : 0,
          yearsEmployed: event.yearsEmployed ? Number(event.yearsEmployed) : 0,
          employerName: isPrimaryEmployed ? event.employerName : null,
          payPeriod: 5, // DL4 specific
          employmentStatus: Number(event.employmentStatus),
          title: isSungageProduct && isPrimaryEmployed ? event.title : null,
        },
      },
      secondaryApplicant: hasCoApplicant
        ? {
            applicantType: 2,
            lastName: event.coBorrowerLastName,
            firstName: event.coBorrowerFirstName,
            SSN: removeSpaces(event.coBorrowerssn),
            dateOfBirth: formatDateForDl4Submission(event.coBorrowerdob),
            email: event.coBorrowerEmail,
            isUSCitizen: event.coBorrowerusCitizen === "Yes",
            isPermanentResident:
              event.coBorrowerusCitizen === "Yes"
                ? null
                : event.coBorrowerxIsPermanentResident === "Yes",
            mobilePhone: unmaskMobile(event.coBorrowerMobilePhone),
            authorization: event.coBorrowerAuthorization,
            eSignatureConsent: event.coBorrowerConsent === "true",
            disclosureConsent: event.coBorrowerConsent === "true",
            myInstallationAddressIsDifferentThanCurrentAddress: false, // only applicable to primary applicant
            differentAddressThanPrimaryApplicant:
              event.isDiffAddressThanPrimary !== "Yes",
            currentAddress:
              event.isDiffAddressThanPrimary === "Yes"
                ? currentAddressOfPrimaryApplicant
                : currentAddressOfCoBorrower,
            myMailingAddressIsDifferentThanCurrentAddress:
              !!event.isCoBorrowerMailingAddressDifferrent,
            mailingAddress: !!event.isCoBorrowerMailingAddressDifferrent
              ? mailingAddressOfCoBorrower
              : undefined,
            employment: {
              employmentType: 1, // DL4 specific
              grossIncome: Number(event.coBorrowerGrossIncome),
              monthsEmployed: event.coBorrowerMonths
                ? Number(event.coBorrowerMonths)
                : 0,
              yearsEmployed: event.coBorrowerYear
                ? Number(event.coBorrowerYear)
                : 0,
              employerName: isSecondaryEmployed
                ? event.coBorrowerEmployerName
                : null,
              payPeriod: 5,
              employmentStatus: Number(event.coBorrowerEmploymentStatus),
              title:
                isSungageProduct && isSecondaryEmployed ? event.title : null,
            },
          }
        : undefined,
    };

    // create new request flow
    // For customers, we would not display Duplicate applications
    const force = authUserStatus === UserAuthStatus.Public ? true : false;
    dispatch(
      postRequestAction({
        model: submitPortalAppPayload,
        history,
        successUrl: "/new-application/confirmation",
        force,
        programTypeCode: Number(event?.loanType),
      })
    );
  };

  useEffect(() => {
    const bindDraftData = () => {
      reset({
        firstName: loanSlice.requestDraft?.firstName,
        lastName: loanSlice.requestDraft?.lastName,
        loanType: loanSlice.requestDraft?.loanProduct,
        loanAmount: loanSlice.requestDraft?.loanAmount,
        email: loanSlice.requestDraft?.email,
        mobilePhone: maskMobile(loanSlice.requestDraft?.mobileNumber),
        hasCoApplicant: "No",
        isDiffAddressThanPrimary: "Yes",
        SSN: "",
      });
    };

    reset({
      isMailingAddressDifferent: undefined,
      isDiffAddressThanPrimary: undefined,
    });
    if (loanSlice.requestDraft) {
      bindDraftData();
      return;
    }

    setTimeout(
      () =>
        reset({
          hasCoApplicant: "No",
          isDiffAddressThanPrimary: "Yes",
          installationAddressIsDifferent: false,
          isMailingAddressDifferent: false,
          SSN: "",
        }),
      1000
    );
  }, [loanSlice.requestDraft, reset]);

  useEffect(() => {
    if (ssn && loanSlice.duplicateApps?.length > 0) {
      history.push("/duplicate/applications");
    }
  }, [loanSlice.duplicateApps, ssn, history]);

  useEffect(() => {
    if (!ssn) {
      dispatch(
        setDuplicateAppsandCurrentAppSubmissionData({
          duplicateApps: [],
          currentApplicationSubmissionData: undefined,
        })
      );
    }
  }, [dispatch, ssn]);

  return (
    <FormProvider {...methods}>
      <FullApplicationForm onSubmit={handleOnSubmit} />
    </FormProvider>
  );
};

export default Request;
