import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "@store/hooks";
import { sungageUtilitiesSelector, getSungageUtilitiesAction} from "@store/slices/app.slice";

const useSungageUtilities = (state: string) => {
  const utilities = useAppSelector(sungageUtilitiesSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (state && (utilities === null || utilities?.state !== state)) {
      dispatch(getSungageUtilitiesAction(state));
    }
  }, [dispatch, utilities, state]);

  return utilities;
};

export default useSungageUtilities;
