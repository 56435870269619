import NumberFormat from "./NumberFormat";

interface ISystemInfoProps {
  label: string;
  value: number | string;
  symbol?: string;
}

const SystemInfoText = ({ label, value, symbol }: ISystemInfoProps) => (
  <p className="display-text text-black fw-normal">
    {label}:{" "}
    <span className="text-primary">
      {typeof value === "string" ? (
        value
      ) : (
        <NumberFormat
          amount={value}
          isCurrency={false}
          symbol={symbol}
          minimumFractionDigits={0}
        />
      )}
    </span>
  </p>
);

export default SystemInfoText;
