import {
  ApplicantType,
  ApplicationType,
} from "@shared/enums/ApplicationStatus";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import AppModal from "./AppModal";
import ElectronicDisclosure from "./ElectronicDisclosure";
import HardCreditPull from "./HardCreditPull";
import PrivacyPolicy from "./PrivacyPolicy";
import SoftCreditPull from "./SoftCreditPull";
import TermsOfUse from "./TermsOfUse";
import DisclosureContainer from "./DisclosureContainer";
import { useAppSelector } from "@store/hooks";
import { sungageDisclosuresSelector, sungagePrequalDisclosuresSelector } from "@store/slices/app.slice";

interface IUserConsentProps {
  applicant?: ApplicantType;
  type: ApplicationType;
  authorization?: any;
  consent?: any;
  isSungageProduct?: boolean;
  applicantEmail?: string;
}

const UserConsent = ({
  applicant,
  type,
  authorization,
  consent,
  isSungageProduct = false,
  applicantEmail
}: IUserConsentProps) => {
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [openHardCreditModal, setOpenHardCreditModal] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [openSoftCreditModal, setOpenSoftCreditModal] = useState(false);
  const [openSungageSoftCreditModal, setOpenSungageSoftCreditModal] = useState(false);
  const [openDisclosure, setOpenDisclosure] = useState(false);
  const [openSungagePrivacyPolicy, setOpenSungagePrivacyPolicy] =
    useState(false);
  const [openSungageTerms, setOpenSungageTerms] = useState(false);
  const [openSungageDisclosure, setOpenSungageDisclosure] = useState(false);
  const sungageDisclosures = useAppSelector(sungageDisclosuresSelector);
  const sungagePrequalDisclosures = useAppSelector(sungagePrequalDisclosuresSelector);
  const sungageElectronicCommunicationsWithEmail = sungageDisclosures?.electronicCommunications?.replace('{EMAIL}', applicantEmail || "");

  const isPrequal = type === ApplicationType.PreQual;
  const isFullApplication = type === ApplicationType.Full;

  return (
    <>
      {isFullApplication && (
        <>
          <AppModal
            show={openHardCreditModal}
            title={`${
              isSungageProduct ? "Soft" : "Hard"
            } Credit Pull Authorization`}
            content={
              isSungageProduct ? (
                <DisclosureContainer
                  content={sungageDisclosures?.creditAuthorization}
                />
              ) : (
                <HardCreditPull />
              )
            }
            onHide={() => setOpenHardCreditModal(false)}
          />
          {isSungageProduct && (
            <>
              <AppModal
                show={openSungageDisclosure}
                title={"Sungage Electronic Disclosure Authorization"}
                content={
                  <DisclosureContainer
                    content={sungageElectronicCommunicationsWithEmail}
                  />
                }
                onHide={() => setOpenSungageDisclosure(false)}
              />
              <AppModal
                show={openSungageTerms}
                title={"Sungage Terms of Use"}
                content={
                  <DisclosureContainer
                    content={sungageDisclosures?.termsAndConditions}
                  />
                }
                onHide={() => setOpenSungageTerms(false)}
              />
              <AppModal
                show={openSungagePrivacyPolicy}
                title={"Sungage Privacy Policy"}
                content={
                  <DisclosureContainer
                    content={sungageDisclosures?.disclosures}
                  />
                }
                onHide={() => setOpenSungagePrivacyPolicy(false)}
              />
            </>
          )}
        </>
      )}
      {isPrequal && (
        <>
          <AppModal
            show={openSoftCreditModal}
            title={"Soft Credit Pull Authorization"}
            content={<SoftCreditPull />}
            onHide={() => setOpenSoftCreditModal(false)}
          />
          {isSungageProduct && (
            <>
              <AppModal
                show={openSungageSoftCreditModal}
                title={"Sungage Soft Credit Pull Authorization"}
                content={
                  <DisclosureContainer
                    content={sungagePrequalDisclosures?.creditAuthorization}
                  />
                }
                onHide={() => setOpenSungageSoftCreditModal(false)}
              />
              <AppModal
                show={openSungageDisclosure}
                title={"Sungage Electronic Disclosure Authorization"}
                content={
                  <DisclosureContainer
                    content={sungagePrequalDisclosures?.electronicCommunications}
                  />
                }
                onHide={() => setOpenSungageDisclosure(false)}
              />
              <AppModal
                show={openSungagePrivacyPolicy}
                title={"Sungage Prequal Disclosures"}
                content={
                  <DisclosureContainer
                    content={sungagePrequalDisclosures?.prequalDisclosures}
                  />
                }
                onHide={() => setOpenSungagePrivacyPolicy(false)}
              />
            </>
          )}
        </>
      )}
      <AppModal
        show={openDisclosure}
        title={"Flic Electronic Disclosure Authorization"}
        content={<ElectronicDisclosure />}
        onHide={() => setOpenDisclosure(false)}
      />
      <AppModal
        show={openTermsModal}
        title={"Flic Terms of Use"}
        content={<TermsOfUse />}
        onHide={() => setOpenTermsModal(false)}
      />
      <AppModal
        show={openPrivacyPolicy}
        title={"Flic Privacy Policy"}
        content={<PrivacyPolicy />}
        onHide={() => setOpenPrivacyPolicy(false)}
      />

      <div className="d-flex flex-column mt-2 ">
        <Row className="mt-4">
          <Col md={12}>
            <h4 className="font-bold">{applicant} Credit Pull Authorization</h4>
            {authorization}
          </Col>
          <Col md={12}>
            {type === ApplicationType.Full && (
              <div className="d-flex flex-column">
                <button
                  type="button"
                  onClick={() => setOpenHardCreditModal(true)}
                  className="link-btn"
                >
                  {isSungageProduct ? "Soft" : "Hard"} Pull Credit Authorization
                </button>
              </div>
            )}
            {isPrequal && (
              <Col>
                <div className="d-flex flex-column">
                  <button
                    type="button"
                    onClick={() => setOpenSoftCreditModal(true)}
                    className="link-btn"
                  >
                    Soft Pull Credit Authorization
                  </button>
                  {isSungageProduct && (
                    <button
                      type="button"
                      onClick={() => setOpenSungageSoftCreditModal(true)}
                      className="link-btn"
                    >
                      Sungage Soft Pull Credit Authorization
                    </button>
                  )}
                </div>
              </Col>
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <h4 className="font-bold">{applicant} Consent</h4>
            {consent}
          </Col>
          <Col md={12}>
            <div className="d-flex flex-column">
              <button
                type="button"
                onClick={() => setOpenDisclosure(true)}
                className="link-btn"
              >
                Flic Electronic Disclosure Authorization
              </button>
              <button
                type="button"
                onClick={() => setOpenTermsModal(true)}
                className="link-btn"
              >
                Flic Terms Of Use
              </button>
              <button
                type="button"
                onClick={() => setOpenPrivacyPolicy(true)}
                className="link-btn"
              >
                Flic Privacy Policy
              </button>

              {isSungageProduct && isFullApplication && (
                <>
                  <button
                    type="button"
                    onClick={() => setOpenSungageDisclosure(true)}
                    className="link-btn"
                  >
                    Sungage Electronic Disclosure Authorization
                  </button>
                  <button
                    type="button"
                    className="link-btn"
                    onClick={() => setOpenSungageTerms(true)}
                  >
                    Sungage Terms Of Use
                  </button>
                  <button
                    type="button"
                    className="link-btn"
                    onClick={() => setOpenSungagePrivacyPolicy(true)}
                  >
                    Sungage Privacy Policy
                  </button>
                </>
              )}

              {isSungageProduct && isPrequal && (
                <>
                  <button
                    type="button"
                    onClick={() => setOpenSungageDisclosure(true)}
                    className="link-btn"
                  >
                    Sungage Electronic Disclosure Authorization
                  </button>
                  <button
                    type="button"
                    className="link-btn"
                    onClick={() => setOpenSungagePrivacyPolicy(true)}
                  >
                    Sungage Prequal Disclosures
                  </button>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UserConsent;
