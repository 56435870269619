import React from "react";
import { LoadingSpinner } from "./Loader";

const InlineLoader = ({ text = "Uploading..." }: { text: string }) => {
  return (
    <div className="d-flex">
      <span className="text-warning">{text}</span> &nbsp;&nbsp;
      <LoadingSpinner height={25} width={25} />
    </div>
  );
};

export default InlineLoader;
