import { Row, Col, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { UseFormSelect } from "@shared/components/UserFormSelect";
import { UseFormTextField } from "@shared/components/TextInput";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  sendLinkFullAppSchema,
  sendLinkPreQualSchema,
} from "@shared/schema/request";
import { MOBILE_MASK } from "@shared/constants/app.constant";
import { emailLinkAction } from "@store/slices/loan.slice";
import { ApplicationType } from "@shared/enums/ApplicationStatus";
import {
  getInternalProductsList,
  onSubmitError,
  unmaskMobile,
  useQuery,
} from "@shared/helpers/global.helper";
import { useEffect } from "react";
import { MyInputMask } from "@shared/components/MyInputMask";
import { UserFormCurrencyField } from "@shared/components/UserFormCurrencyField";
import { appLoadingSelector, appUserSelector } from "@store/slices/app.slice";
import { isSungageLoanProduct } from "@shared/helpers/application.helper";
import useSungageEquipment from "@hooks/useSungageEquipment";
import SystemInfoText from "@shared/components/SystemInfoText";
import useTotalSystemSize from "@hooks/useTotalSystemSize";
import { GoogleAutoComplete } from "@shared/components/GoogleAutoComplete";
import {
  IEmailApplicationRequestPayload,
  ProductLos,
} from "@shared/interfaces/PortalApplicationTypes";
import SystemInfoSection from "@shared/components/SystemInfoSection";

const SendLink = () => {
  let { type } = useParams<{ type: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const user = useAppSelector(appUserSelector);
  const sungageEquipment = useSungageEquipment();
  const isLoading = useAppSelector(appLoadingSelector);

  const methods = useForm({
    resolver: yupResolver(
      type === ApplicationType.Full
        ? sendLinkFullAppSchema
        : sendLinkPreQualSchema
    ),
  });

  const { control, handleSubmit, watch, setValue } = methods;

  const onSubmit = (event: any) => {
    const model: IEmailApplicationRequestPayload = {
      productLos: isSungageProduct ? ProductLos.SUNGAGE : ProductLos.DL4,
      firstName: event?.firstName,
      lastName: event?.lastName,
      email: event?.email,
      mobileNumber: unmaskMobile(event?.mobileNumber),
      xprogramtype: Number(event?.loanType),
      loanAmount: event?.loanAmount ? parseInt(event?.loanAmount) : null,
      projectId: event?.projectId || null,
      applicationType: type as ApplicationType,
      panelCost: isSungageProduct && event?.panelCost ? event.panelCost : null,
      panelSize: isSungageProduct ? totalSolarSize : null,
      batteryCost:
        isSungageProduct && event?.batteryCost ? event.batteryCost : null,
      batterySize: isSungageProduct ? totalBatterySize : null,
      moduleType: isSungageProduct ? Number(event.moduleType) : null,

      panelQuantity:
        isSungageProduct && event?.panelQuantity
          ? Number(event.panelQuantity)
          : null,
      batteryType: isSungageProduct ? Number(event.batteryType) : null,
      batteryExtensionType: isSungageProduct
        ? Number(event.batteryExtensionType)
        : null,
      batteryQuantity:
        isSungageProduct && event?.batteryQuantity
          ? Number(event.batteryQuantity)
          : null,
      batteryExtensionQuantity:
        isSungageProduct && event?.batteryExtensionQuantity
          ? Number(event.batteryExtensionQuantity)
          : null,
      installationAddressLine: isSungageProduct
        ? event.installationAddressLine
        : null,
      installationCity: isSungageProduct ? event.installationCity : null,
      installationState: isSungageProduct ? event.installationState : null,
      installationZipCode: isSungageProduct ? event.installationZipCode : null,
    };
    dispatch(emailLinkAction({ model, history, type }));
  };

  const loanType = watch("loanType");

  const isSungageProduct = isSungageLoanProduct(loanType);

  const [
    moduleType,
    batteryType,
    batteryExtensionType,
    panelQuantity,
    batteryQuantity,
    batteryExtensionQuantity,
  ] = watch([
    "moduleType",
    "batteryType",
    "batteryExtensionType",
    "panelQuantity",
    "batteryQuantity",
    "batteryExtensionQuantity",
  ]);

  const { totalSolarSize, totalBatterySize } = useTotalSystemSize({
    moduleType,
    batteryType,
    batteryExtensionType,
    panelQuantity,
    batteryQuantity,
    batteryExtensionQuantity,
    los: isSungageProduct ? ProductLos.SUNGAGE : ProductLos.DL4,
  });

  return (
    <Col xs={12} md={10} lg={7} className="mx-auto preQual-request">
      <div className="mt-3">
        <h3 className="h3 text-primary">Send {type} Application</h3>
        <hr className="text-primary height-2px" />
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit, onSubmitError())}
        >
          <Row className="mt-4">
            <Col xs={12}>
              <h4 className="font-bold">Customer Contact</h4>
            </Col>
            <Col xs={12} md={6} className="mt-3 mt-md-0">
              <UseFormTextField
                placeholder="First Name"
                control={control}
                name="firstName"
              />
            </Col>
            <Col xs={12} md={6} className="mt-3 mt-md-0">
              <UseFormTextField
                placeholder="Last Name"
                control={control}
                name="lastName"
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <UseFormTextField
                placeholder="Email"
                control={control}
                name="email"
              />
            </Col>
            <Col xs={12} md={6} className="mt-3">
              <MyInputMask
                control={control}
                defaultValue={""}
                name="mobileNumber"
                mask={MOBILE_MASK}
                placeholder="Mobile Phone"
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              <h4 className="font-bold">Loan Product</h4>
            </Col>
            <Col xs={12} md={6} className="mt-3 mt-md-0">
              <UseFormSelect
                list={getInternalProductsList(
                  user?.role,
                  user?.userDetails?.accessManagement,
                  type === ApplicationType.PreQual
                )}
                control={control}
                name="loanType"
                placeholder="Loan Product"
              />
            </Col>

            {type === ApplicationType.Full && (
              <Col xs={12} md={6} className="mt-3 mt-md-0">
                <UserFormCurrencyField
                  placeholder="Loan Amount"
                  control={control}
                  type="number"
                  name="loanAmount"
                  // disabled={isSungageProduct}
                />
              </Col>
            )}

            {isSungageProduct && type === ApplicationType.Full && (
              <>
                <Row className="mt-3">
                  <Col xs={12} className="mt-3">
                    <h4 className="font-bold">Installation Address</h4>
                  </Col>
                  <Col xs={12}>
                    <GoogleAutoComplete
                      placeholder="Street (Installation Address)"
                      control={control}
                      name="installationAddressLine"
                      onSelect={(address) => {
                        setValue("installationAddressLine", address.street);
                        setValue("installationCity", address.city);
                        setValue("installationState", address.state);
                        setValue("installationZipCode", address.zipCode);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={12} md={6}>
                    <UseFormTextField
                      placeholder="City (Installation Address)"
                      control={control}
                      name="installationCity"
                    />
                  </Col>
                  <Col xs={6} md={2} className="mt-3 mt-md-0">
                    <UseFormTextField
                      placeholder="State"
                      control={control}
                      name="installationState"
                    />
                  </Col>
                  <Col xs={6} md={4} className="mt-3 mt-md-0">
                    <UseFormTextField
                      placeholder="Zip Code"
                      control={control}
                      name="installationZipCode"
                    />
                  </Col>
                </Row>
                <FormProvider {...methods}>
                  <SystemInfoSection />
                </FormProvider>
              </>
            )}
          </Row>

          {type === ApplicationType.PreQual && (
            <Row className="mt-4">
              <Col xs={12}>
                <h4 className="font-bold mb-0">Loan Amount (Optional)</h4>
                <p className="sub-detail mb-0">
                  If loan amount is provided, customer will be able to convert
                  prequal application to a full application after pre-qual. If
                  omitted, a separate application will need to be sent to the
                  borrower to complete the full application.
                </p>
              </Col>
              <Col xs={12} md={6} className="mt-3">
                <UserFormCurrencyField
                  placeholder="Loan Amount"
                  control={control}
                  name="loanAmount"
                />
              </Col>
            </Row>
          )}

          <Row className="mt-4">
            <Col xs={12}>
              <h4 className="font-bold">Project ID Information (Optional)</h4>
            </Col>

            <Col xs={12} md={6}>
              <UseFormTextField
                placeholder="Installer Project Identifier"
                control={control}
                name="projectId"
              />
            </Col>
          </Row>

          <div className="mt-5 d-flex justify-content-center mb-5">
            <Button variant="primary" type="submit" disabled={isLoading}>
              Email {type} Application Link
            </Button>
          </div>
        </form>
      </div>
    </Col>
  );
};

export default SendLink;
