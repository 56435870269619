import useSungageEquipment from "@hooks/useSungageEquipment";
import { Col, Row } from "react-bootstrap";
import { UseFormSelect } from "./UserFormSelect";
import { UseFormTextField } from "./TextInput";
import { useFormContext } from "react-hook-form";
import useTotalSystemSize from "@hooks/useTotalSystemSize";
import { ProductLos } from "@shared/interfaces/PortalApplicationTypes";
import SystemInfoText from "./SystemInfoText";
import { UserFormCurrencyField } from "./UserFormCurrencyField";
import { isSungageLoanProduct } from "@shared/helpers/application.helper";
import { useEffect } from "react";

const SystemInfoSection = () => {
  const sungageEquipment = useSungageEquipment();

  const { control, watch, setValue } = useFormContext();

  const [
    loanAmount,
    loanType,
    panelCost,
    batteryCost,
    moduleType,
    batteryType,
    batteryExtensionType,
    panelQuantity,
    batteryQuantity,
    batteryExtensionQuantity,
  ] = watch([
    "loanAmount",
    "loanType",
    "panelCost",
    "batteryCost",
    "moduleType",
    "batteryType",
    "batteryExtensionType",
    "panelQuantity",
    "batteryQuantity",
    "batteryExtensionQuantity",
  ]);

  const isSungageProduct = isSungageLoanProduct(loanType);

  const { totalSolarSize, totalBatterySize, moduleSize, batterySize } =
    useTotalSystemSize({
      moduleType,
      batteryType,
      batteryExtensionType,
      panelQuantity,
      batteryQuantity,
      batteryExtensionQuantity,
      los: isSungageProduct ? ProductLos.SUNGAGE : ProductLos.DL4,
    });

  useEffect(() => {
    setValue("totalBatterySize", totalBatterySize, { shouldValidate: true });
  }, [totalBatterySize, setValue]);

  useEffect(() => {
    if (
      isSungageProduct &&
      (typeof loanAmount !== "undefined" || typeof batteryCost !== "undefined")
    ) {
      const calculatedSolarCost =
        (loanAmount !== undefined ? Number(loanAmount) : 0) -
        (batteryCost !== undefined ? Number(batteryCost) : 0);

      setValue("panelCost", calculatedSolarCost, { shouldValidate: true });
    }
  }, [loanAmount, batteryCost, isSungageProduct, setValue]);

  const selectedModule =
    moduleType && sungageEquipment?.modules !== undefined
      ? sungageEquipment?.modules.find(
          (item) => item.value === Number(moduleType)
        )
      : undefined;

  const selectedBattery =
    batteryType && sungageEquipment?.batteries !== undefined
      ? sungageEquipment?.batteries.find(
          (item) => item.value === Number(batteryType)
        )
      : undefined;

  const selectedBatteryExtension =
    batteryExtensionType && sungageEquipment?.batteryExtensions !== undefined
      ? sungageEquipment?.batteryExtensions.find(
          (item) => item.value === Number(batteryExtensionType)
        )
      : undefined;

  const isPanelsFieldsDisabled =
    totalSolarSize === 0 && moduleSize === 0 && selectedModule?.text === "None";

  const isBatteryQuantityDisabled =
    batterySize === 0 &&
    selectedBattery !== undefined &&
    selectedBattery?.text === "None";

  const isBatteryExtensionQuantityDisabled =
    selectedBatteryExtension?.text === "None";

  const isBatteryCostDisabled =
    totalBatterySize === 0 &&
    isBatteryQuantityDisabled &&
    isBatteryExtensionQuantityDisabled;

  const isSolarCostDisabled = totalSolarSize === 0 && isPanelsFieldsDisabled;

  return (
    <>
      <Row className="mt-4">
        <h4 className="font-bold">Estimated System Size</h4>
        <Col xs={12}>
          <Row className="align-items-start">
            <Col md={3} xs={12}>
              <label className="form-check-label">Panels:</label>
            </Col>
            <Col md={9} className="mt-3 mt-md-0">
              <Row>
                <Col md={6} xs={12}>
                  <UseFormSelect
                    list={sungageEquipment?.modules}
                    control={control}
                    name="moduleType"
                    placeholder="Module Model Number"
                  />
                </Col>
                <Col md={6} xs={12} className="mt-3 mt-md-0">
                  <UseFormTextField
                    placeholder="Panel Quantity"
                    control={control}
                    name="panelQuantity"
                    type="number"
                    disabled={isPanelsFieldsDisabled}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3 align-items-start">
            <Col md={3}>
              <label className="form-check-label">Batteries:</label>
            </Col>
            <Col md={9} className="mt-3 mt-md-0">
              <Row>
                <Col md={6} xs={12}>
                  <UseFormSelect
                    list={sungageEquipment?.batteries}
                    control={control}
                    name="batteryType"
                    placeholder="Battery Type"
                  />
                </Col>
                <Col md={6} xs={12} className="mt-3 mt-md-0">
                  <UseFormTextField
                    placeholder="Battery Quantity"
                    control={control}
                    name="batteryQuantity"
                    type="number"
                    disabled={isBatteryQuantityDisabled}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3 align-items-start">
            <Col md={3}>
              <label className="form-check-label">Battery Extensions:</label>
            </Col>
            <Col md={9} className="mt-3 mt-md-0">
              <Row>
                <Col md={6} xs={12}>
                  <UseFormSelect
                    list={sungageEquipment?.batteryExtensions}
                    control={control}
                    name="batteryExtensionType"
                    placeholder="Battery Extension Model"
                  />
                </Col>
                <Col md={6} xs={12} className="mt-3 mt-md-0">
                  <UseFormTextField
                    placeholder="Battery Extension Quantity"
                    control={control}
                    name="batteryExtensionQuantity"
                    type="number"
                    disabled={isBatteryExtensionQuantityDisabled}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12} md={6}>
              <SystemInfoText
                label="Total Solar System Size"
                value={totalSolarSize}
                symbol=" kW"
              />
            </Col>
            <Col xs={12} md={6} className="mt-2 mt-md-0">
              <SystemInfoText
                label="Module Wattage"
                value={moduleSize}
                symbol=" W"
              />
            </Col>
          </Row>
          <Row className="mt-2 mt-md-4">
            <Col xs={12} md={6}>
              <SystemInfoText
                label="Total Battery Size"
                value={totalBatterySize}
                symbol=" kWh"
              />
              <UseFormTextField
                control={control}
                name="totalBatterySize"
                type="hidden"
                controlledValue={totalBatterySize}
              />
            </Col>
            <Col xs={12} md={6} className="mt-2 mt-md-0">
              <SystemInfoText
                label="Battery Wattage"
                value={batterySize}
                symbol=" kWh"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <h4 className="font-bold">System Cost</h4>
        <Col xs={12}>
          <Row className="align-items-center">
            <Col xs={12} md={6}>
              <Row className="align-items-center">
                <Col md={6} className="mt-3 mt-md-0">
                  <label className="form-check-label">
                    Total Battery Cost:
                  </label>
                </Col>
                <Col>
                  <UserFormCurrencyField
                    placeholder="Battery Cost"
                    control={control}
                    name="batteryCost"
                    type="number"
                    disabled={isBatteryCostDisabled}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="mt-3 mt-md-0">
              <SystemInfoText label="Solar Cost" value={`$${panelCost}`} />
              <UseFormTextField
                control={control}
                name="panelCost"
                type="hidden"
                disabled={isSolarCostDisabled}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SystemInfoSection;
