import { useMemo } from "react";
import useSungageEquipment from "./useSungageEquipment";
import { ProductLos } from "@shared/interfaces/PortalApplicationTypes";
import { useAppSelector } from "@store/hooks";
import { issueLoanDocTypeSelector } from "@store/slices/commonData.slice";

interface IUseTotalSystemSizeArgs {
  moduleType: string;
  batteryType: string;
  batteryExtensionType: string;
  panelQuantity: string;
  batteryQuantity: string;
  batteryExtensionQuantity: string;
  los: ProductLos;
}

interface ISystemEquipment {
  label?: string;
  value: string | number;
  text?: string;
  manufacturer?: string;
  size?: number;
}

interface ILosEquipment {
  modules: ISystemEquipment[];
  batteries: ISystemEquipment[];
  batteryExpansions: ISystemEquipment[];
  inverters: ISystemEquipment[];
}

const useTotalSystemSize = ({
  moduleType,
  batteryType,
  batteryExtensionType,
  panelQuantity,
  batteryQuantity,
  batteryExtensionQuantity,
  los,
}: IUseTotalSystemSizeArgs) => {
  const sungageEquipment = useSungageEquipment();
  const dl4Equipment = useAppSelector(issueLoanDocTypeSelector);

  const equipment: ILosEquipment = useMemo(() => {
    switch (los) {
      case ProductLos.DL4:
        return {
          modules: dl4Equipment.moduleType,
          batteries: dl4Equipment.batteryType,
          inverters: dl4Equipment.inverterType,
          batteryExpansions: dl4Equipment.batteryExpansionType,
        };

      case ProductLos.SUNGAGE:
        return {
          modules: sungageEquipment.modules,
          batteries: sungageEquipment.batteries,
          inverters: dl4Equipment.inverterType,
          batteryExpansions: sungageEquipment.batteryExtensions,
        };
    }
  }, [los, dl4Equipment, sungageEquipment]);

  const {
    totalBatterySize,
    totalSolarSize,
    moduleSize,
    batterySize,
    batteryExtensionSize,
  } = useMemo(() => {
    const moduleSize =
      equipment?.modules.find((i: any) => i.value === Number(moduleType))
        ?.size || 0;
    const batterySize =
      equipment?.batteries.find((i: any) => i.value === Number(batteryType))
        ?.size || 0;

    const batteryExtensionSize =
      equipment?.batteryExpansions.find(
        (i: any) => i.value === Number(batteryExtensionType)
      )?.size || 0;

    const totalSolarSize = Number(panelQuantity) * (Number(moduleSize) / 1000);
    const totalBatterySize =
      Number(batteryQuantity) * Number(batterySize) +
      Number(batteryExtensionQuantity) * Number(batteryExtensionSize);

    return {
      totalSolarSize: isNaN(totalSolarSize) ? 0 : totalSolarSize,
      totalBatterySize: isNaN(totalBatterySize) ? 0 : totalBatterySize,
      moduleSize,
      batterySize,
      batteryExtensionSize,
    };
  }, [
    equipment,
    panelQuantity,
    batteryQuantity,
    batteryExtensionQuantity,
    moduleType,
    batteryType,
    batteryExtensionType,
  ]);

  return {
    totalBatterySize,
    totalSolarSize,
    moduleSize,
    batterySize,
    batteryExtensionSize,
  };
};

export default useTotalSystemSize;
