/**
 * Fields in [] are the original field name that system sends to DL4 API
 * Fields in <> are the original field name that system maps from Sungage API
 */

import {
  ApplicationType,
  PortalDecisionStatus,
} from "@shared/enums/ApplicationStatus";
import {
  DocumentMetadata,
  ICustomNotes,
  IDecision,
  IDecisionCalcRec,
  IPrequalifiedOffer,
  IStipulation,
  IXPrequalProductsTypeValues,
} from "./Application";
import { EmploymentStatus } from "@shared/enums/EmploymentStatus";
import { IEquipmentForDisplay } from "./SungageTypes";

export enum ProductLos {
  DL4 = "DL4",
  SUNGAGE = "SUNGAGE",
}

export enum Task {
  PREQUAL = "PreQual",
  FULL_APPLICATION = "Full Application",
  SUBMIT_STIPS = "Submit Stipulations",
  UPLOAD_ID = "Upload ID",
  UPLOAD_CONTRACT = "Upload Contract",
  SIGN_LOAN_DOC = "Signed Loan Docs",
}

export interface ITask {
  name: Task;
  state: "inactive" | "active" | "completed";
  order: number;
}

export interface ISubmitPortalApplicationBase {
  productLos: ProductLos;
  prequal: boolean;
  isMultiPrequal: boolean;
  hasCoApplicant: boolean;
  xprogramtype: number;
  loanAmount: number; // [totalCashPrice]
  xSecondSubmission: false; // DL4 Specific
  appSource: "Flic Frontend"; // DL4 Specific
}

interface IApplicantEmploymentBase {
  employmentType: number; // DL4 specific
  grossIncome: number;
  payPeriod: 5; // DL4 specific
  employmentStatus: number; // Fixed for DL4
}

export interface ISubmitPortalFullApplication
  extends ISubmitPortalApplicationBase {
  primaryApplicant: IApplicantForFullApplication;
  secondaryApplicant?: IApplicantForFullApplication;
  installationAddress: IInstallationAddress;
  isInstallationAddressSame: boolean;
  //   hasCoApplicant: boolean; // DO NOT NEED TO SEND THIS TO DL4
  //   ISjOINT: boolean; // DO NOT NEED TO SEND THIS TO DL4
  moduleType: number | null;
  panelSize: number | null;
  panelCost: number | null;
  batterySize: number | null;
  batteryCost: number | null;
  panelQuantity: number | null;
  batteryType: number | null;
  batteryExtensionType: number | null;
  batteryQuantity: number | null;
  batteryExtensionQuantity: number | null;
}

export interface IModifyPortalFullApplication {
  productLos: ProductLos;
  xprogramtype: number;
  loanAmount: number;
  installationAddress: IInstallationAddress;
  isInstallationAddressUpdated: boolean;
  isInstallationAddressDifferent: boolean;
}

export interface IPortalModifyApplicationResponseData {
  success: boolean;
  message: string;
}

export interface ISubmitPortalPrequalApplication
  extends ISubmitPortalApplicationBase {
  prequalProducts: IXPrequalProductsTypeValues[]; // [xPrequalProducts]
  primaryApplicant: IApplicantForPrequalApplication;
  secondaryApplicant?: IApplicantForPrequalApplication;
}

interface IApplicantBase {
  applicantType: number; // DL4 specific
  lastName: string;
  firstName: string;
  SSN: string;
  dateOfBirth: string;
  authorization: boolean;
  eSignatureConsent: boolean;
  disclosureConsent: boolean; // [disclosureAck]
  currentAddress: IApplicantAddressBase;
  differentAddressThanPrimaryApplicant?: boolean;
}

interface IApplicantForFullApplication extends IApplicantBase {
  email: string;
  mobilePhone: string;
  isUSCitizen: boolean;
  isPermanentResident: boolean | null; // Sungage doesn't require this. [xIsPermanentResident]
  mailingAddress?: IApplicantAddressBase;
  myInstallationAddressIsDifferentThanCurrentAddress: boolean;
  myMailingAddressIsDifferentThanCurrentAddress: boolean;
  employment: IApplicantEmploymentForFullApplication;
  /** Required for Secondary applicant */
}

interface IApplicantForPrequalApplication extends IApplicantBase {
  employment: IApplicantEmploymentBase;
}

interface IApplicantEmploymentForFullApplication
  extends IApplicantEmploymentBase {
  monthsEmployed: number;
  yearsEmployed: number;
  employerName: string;
  /** Sungage-only field. Set it to null for DL4 products. */
  title: string | null;
}

export interface IApplicantAddressBase {
  ownershipType: number; // DL4 specific
  addressType: number; // DL4 specific
  addressFormat: number; // DL4 specific
  postalCode: string;
  addressLine: string;
  city: string;
  state: string;
  /** Required for Current address */
  yearsAtResidence?: number;
  /** Required for Current address */
  monthsAtResidence?: number;
}

interface IInstallationAddress {
  propertyType: number | null;
  addressLine: string; // [xInstallationAddressLine]
  city: string; // [xInstallationCity]
  state: string; // [xInstallationState]
  zipCode: string; // [xInstallationZipCode]
  installationHomeOwner: number; // [xStatedInstallationHomeOwner]
  installationResidency: number; // [installationResidency]
  /** Sungage-only field. Set it to null for DL4 products. */
  reverseMortgage: boolean | null;
  /** Sungage-only field. Set it to null for DL4 products. */
  mortgagePayment: number | null;
}

/** Portal Offer type */
export interface ILoanOfferResponse {
  xprogramtype: string; // Take this from the request
  loanAmount: number; // [maxAmount]
  xHasIntroPeriod: boolean;
  xInitialRate: number;
  payment: number;
  xIntroTerm: number;
  xFinalPmtDiff: boolean;
  term: number;
  xFinalRate: number;
  xFullyAmortizedPayment: number;
  xFullyAmortizedPaymentWithV: number;
  xFinalPaymentWithNoVoluntary: number;
  xFinalPaymentWithVoluntary: number;
  xOfferDiscLang: number;
}

export interface IPortalApplicantResponse {
  firstName: string;
  lastName: string;
  email: string;
  isUSCitizen: boolean | null;
  mobilePhone: string | null;
  fullName: string;
  currentAddress: {
    postalCode: string;
    addressLine: string; // [addressLine1]
    city: string;
    state: string;
    /** Required for Current address */
    yearsAtResidence?: number;
    /** Required for Current address */
    monthsAtResidence?: number;
  } | null;
  applicantId: number | null; // [DL4 specific] [For identifying the applicant update on the modify form]
  isPermanentResident: boolean | null; // [xIsPermanentResident]
  employment: {
    title: string; // [Sungage specific]
    employmentType: number; // DL4 specific
    grossIncome: number;
    monthsEmployed: number;
    yearsEmployed: number;
    employerName: string;
    employmentStatus: EmploymentStatus;
  } | null;
  differentAddressThanPrimaryApplicant?: boolean; // [xDiffAddressThanApplicant]
  mailingAddress?: {
    mailingAddress: string; // [xMailingAddress]
    mailingCity: string; // [xMailingCity]
    mailingState: string; // [xMailingState]
    mailingPostalCode: string; // [xMailingPostalCode]
  };
  consumerStatementExist?: boolean; // [xConsStmtExist]
  consumerStatementManualClear?: boolean; // [xConsStmtManualClear]
  consumerStatementNotes?: string; // [xConsStmtNotes]
  xOFACMatch?: string; // [xOFACMatch]
  xOfacManualClear?: boolean; // [xOfacManualClear]
  myInstallationAddressIsDifferentThanCurrentAddress: boolean | null;
  myMailingAddressIsDifferentThanCurrentAddress: boolean | null;
}

export enum PortalApplicationStatus {
  Prequal = "Prequal",
  Application = "Application",
  WaitingOnDocs = "WaitingOnDocs",
  NTPReview = "NTPReview",
  QCReview = "QCReview",
  NTPIssued = "NTPIssued",
  InstallReady = "InstallReady",
  RFFReview = "RFFReview",
  RFFIssued = "RFFIssued",
  Funded = "Funded",
  PTOReview = "PTOReview",
  PTOIssued = "PTOIssued",
  Cancelled = "Cancelled",
  Duplicate = "Duplicate",
  Expired = "Expired",
  ChangeOrder = "ChangeOrder",
  UnderReview = "UnderReview", // For all other unlisted application status
}

export const PortalApplicationStatusLabel: Record<
  PortalApplicationStatus,
  string
> = {
  [PortalApplicationStatus.Prequal]: "Prequal",
  [PortalApplicationStatus.Application]: "Application",
  [PortalApplicationStatus.WaitingOnDocs]: "Waiting On Docs",
  [PortalApplicationStatus.NTPReview]: "NTP Review",
  [PortalApplicationStatus.QCReview]: "QC Review",
  [PortalApplicationStatus.NTPIssued]: "NTP Issued",
  [PortalApplicationStatus.InstallReady]: "Install Ready",
  [PortalApplicationStatus.RFFReview]: "RFF Review",
  [PortalApplicationStatus.RFFIssued]: "RFF Issued",
  [PortalApplicationStatus.Funded]: "Funded",
  [PortalApplicationStatus.PTOReview]: "PTO Review",
  [PortalApplicationStatus.PTOIssued]: "PTO Issued",
  [PortalApplicationStatus.Cancelled]: "Cancelled",
  [PortalApplicationStatus.Duplicate]: "Duplicate",
  [PortalApplicationStatus.Expired]: "Expired",
  [PortalApplicationStatus.ChangeOrder]: "Change Order",
  [PortalApplicationStatus.UnderReview]: "Under Review",
} as const;

export enum PortalIssueLoanDocStatus {
  DEFAULT_NOT_GENERATED = "DEFAULT_NOT_GENERATED",
  DOCS_SENT_TO_BORROWER = "DOCS_SENT_TO_BORROWER",
  DOCS_COMPLETED = "DOCS_COMPLETED",
  OTHER = "OTHER",
}

export interface IPortalApplication {
  los: ProductLos;
  prequal: boolean;
  /**
   * isLegacyPrequal field to identify older prequal applications before the multi-prequal update. Specific to DL4
   */
  isLegacyPrequal: boolean | null;
  programTypeCode: number; // [xprogramtype]
  appDenialReasons: Array<{
    description: string;
    category: string;
  }> | null; // DL4 specific for now
  appStipulations?: Array<IStipulation>; // DL4 specific for now
  status: {
    applicationStatus?: PortalApplicationStatus;
    decisionStatus?: PortalDecisionStatus;
    loanDocStatus: PortalIssueLoanDocStatus; // string | null; // [xApiEsignStatus?: LoanDocsStatus]
    qcReviewStatus?: string; // [DL4 specific] [xQcReviewStatus] <>
  };
  losAppNumber?: string;
  losApplicationId?: string | number;
  primaryApplicant?: IPortalApplicantResponse;
  coApplicant: IPortalApplicantResponse | null;
  customNotes?: ICustomNotes[]; // [DL4 specific]
  decisionMetrics?: {
    // [decision]
    // [DL4 specific]
    combinedDecisionCalcRec: IDecisionCalcRec;
    applicantDecisionCalcRec: IDecisionCalcRec;
    coApplicantDecisionCalcRec: IDecisionCalcRec;
    paperGrade: string; // [paperGrade]
  };
  installer: {
    installerName?: string; // [dealer.name] [Grab this from Portal DB] // TODO: Test this
    email?: string; // [xEmployeeEmail] [Grab this from Portal DB]
    name?: string; // [xEmployeeName] [Grab this from Portal DB]
    projectId?: string; // [xProjectID]
  } | null;
  applicationDates: {
    createdOn: number; // [createdOn]
    fundDate: number; // [fundingInfo.fundDate]
    prequalDate: number | null; // unix timestamp as date // [DL4 specific] [xPrequalDate]
    fullApplicationDate: number; // unix timestamp as date // [xFullApplicationDate] <>
    ntpIssuedDate: number | null; // unix timestamp as date // [xNtpIssuedDate] <>
  };
  verifications: {
    coreLogic: {
      // DL4 specific
      firstHomeOwner: {
        firstNameAndMiddleInitial?: string; // [corelogicFirstNameAndMiddleInitial]
        lastName?: string; // [corelogicLastName]
      };
      secondHomeOwner: {
        firstNameAndMiddleInitial?: string; // [coreLogicFirstNameAndMiddleInitial2]
        lastName?: string; //[coreLogicLastName2]
      };
      ownershipRightsCode?: string; // [coreLogicOwnershipRightsCode]
      isCorporate?: boolean; // [corelogicIsCorporate]
      propertyTypeCode?: string; // [corelogicPropertyTypeCode]
      propertyIsManufacturedHome?: boolean; // [corelogicPropertyIsManufacturedHome]
    };
    hasUwException?: boolean; // [DL4 specific] [xHasUwException] <>
    exception?: string; // [DL4 specific] [xException] <>
  } | null;
  loanOffer: {
    payment?: number; // [xFullyAmortizedPayment] <loan.maxMonthlyPayment>
    introPayment: number; // [xIntroPayment || loanInformation?.currentLoanVersion?.payment] <loan.initialMonthlyPayment>
    rate?: number; // [xFinalRate] <loan.rate>
    flicPlatformFee?: number; // [xFlicPlatformFee] <>
    flicPlatformFeePercent?: number; // [xFlicPlatformFeePercent] <>
    sellersPoints?: number; // [xSellersPoints] <>
    sellersPointsPercent?: number; // [xSellersPointsPercent] <>
    netToFlicFinancial?: number; // [xFundingNetToFlicFinancial] <>
    netToDealer?: number; // [xFundingNetToDealer] <>
    expirationDate?: number; // [xExpirationDate] <applicationDetails.creditDeadline>
    introRate?: number; // [xInitialRate] <loan.rate>
    introTerm?: number; // [xIntroTerm] <>
    taxCredit?: number; // [xTaxCredit] <loan.zeroInterestPortion>
    paymentFtcApplied?: number; // [xFullyAmortizedPaymentWithV] <loan.initialMonthlyPayment>
    finalPayment?: number; // [xFinalPaymentWithNoVoluntary] <loan.maxMonthlyPayment>
    finalPaymentFtcApplied?: number; // [xFinalPaymentWithVoluntary] <loan.initialMonthlyPayment>
    amountFinanced: number; // [amountFinanced] <loan.amountDue>
    contractAPR?: number; // [contractAPRStandard] <loan.apr>
    contractDate?: number; // [contractDateStandard] <>
    firstPaymentDate?: number; // [firstPaymentDateStandard] <>
    maturityDate?: number; // [maturityDateStandard] <>
    term?: number; // [term] <loan.term>
    createdOn?: number; // [createdOn] <applicationDetails.applicationDate>
    offerDiscLang: string | null; // [xOfferDiscLang] <>
    solarCost?: number; // <projectDetails.solarCost>
    batteryCost?: number; // <projectDetails.batteryCost>
  };
  installationAddress: {
    addressLine: string; // [xInstallationAddressLine]
    city: string; // [xInstallationCity]
    state: string; // [xInstallationState]
    zipCode: string; // [xInstallationZipCode]
    /**
     * For DL4: Older applications would utilze the xInstalaltionHomeOwner to display or to prefilled values in the form. Application will utilize the xStatedInstallationHomeOwner field for submission to DL4.
     */
    installationHomeOwner: number | null; // [xStatedInstallationHomeOwner]
    installationResidency: number | null; // [installationResidency]
    isInstallationAddressDifferent: boolean | null; // [!xInstalationAddressSame]
    propertyType: number | null;
  };
  systemInfo?: {
    panels: IEquipmentForDisplay | null;
    batteries: IEquipmentForDisplay | null;
    batteryExpansions: IEquipmentForDisplay | null;
    inverters: IEquipmentForDisplay | null;
  };
  decisionRules?: IDecision; // [DL4 specific]
  prequalifiedOffers?: IPrequalifiedOffer[]; // [DL4 specific]
  losInternalClientId: number | null; // [DL4 specific]
  prequalProducts?: Array<any>; // [DL4 specific] [xPrequalProducts]
  declinedReasons: IDeclinedReasons[];
  noDecisionReasons: INoDecisonReasons[];
  isCreditFreeze: boolean;
  portalTasks: ITask[];
  documents: IPortalDocuments | null;
  documentsMetadata: IPortalDocumentsMetadata | undefined; //Dl4DocumentMetadata[] | undefined
  portalAccessibility: IPortalAccessibility;
}

export interface IPortalDocumentsMetadata {
  additionalDocuments: IDocumentMetadata[];
  loanDocPackage: IDocumentMetadata[];
}

export interface IDocumentMetadata {
  label: string;
  documentId: string;
  eSignIntegrationId: string | null;
}

export interface INoDecisonReasons {
  description: string;
}

export interface IDeclinedReasons {
  description: string;
  category: "applicant" | "coapplicant" | null;
}

export interface IPortalResponse<T> {
  data: T;
  errors: any;
  message: string;
}

export type PortalSubmitApplicationResponseType = IPortalResponse<{
  productLos: ProductLos;
  losAppNumber: string | null;
  duplicates?: any[];
}>;

export type PortalModifyApplicationResponseType = IPortalResponse<{
  success: boolean;
  message: string;
}>;

export enum PortalTaskProcessingStatus {
  Completed = "Completed",
  Failed = "Failed",
}

export interface IIssueLoanDocsRequestPayload {
  moduleType: number;
  panelQuantity: number;
  inverterType: number;
  inverterQuantity: number;
  batteryType: number;
  batteryQuantity: number;
  batteryExpansionType: number;
  batteryExpansionQuantity: number;
  otherText: string | null;
  quantityofOtherItems: number;
  totalSolarSystemSize: number;
  moduleSize: number;
  batterySize: number;
  batteryExpansionSize: number;
  totalBatterySize: number;
  vaSurvivorshipElection: number;
}

export interface IPortalDocuments {
  stipulations: IDocumentStipulation[];
  applicantsIdentification: IDocumentStipulation[];
  solarContract: IDocumentStipulation | null;
  additionalDocuments: IDocumentStipulation[];
  permissionToOperate: IDocumentStipulation | null;
  installationPhotos: IDocumentStipulation | null;
}

export enum DocStipulationType {
  Applicant = "Applicant",
  CoApplicant = "CoApplicant",
}

export interface IDocumentStipulation {
  stipulationId: string;
  name: string;
  type: DocStipulationType | null;
  description: string;
  stipulationComment: string | null; // Stipulation failure/wrong message/comments
  isDocumentUploaded: boolean;
  isDocumentFailed: boolean;
  documentIds: string[];
  urlForDocumentUpload: string | null; // Sungage specific for now, We can try for uploading doc
}

interface IModifyApplicantBase {
  applicantType: number; // DL4 specific
  lastName: string;
  firstName: string;
  authorization: boolean;
  eSignatureConsent: boolean;
  disclosureConsent: boolean; // [disclosureAck]
  currentAddress: IApplicantAddressBase;
  differentAddressThanPrimaryApplicant?: boolean;
}

export interface IModifyApplicantForFullApplication
  extends IModifyApplicantBase {
  email: string;
  mobilePhone: string;
  isUSCitizen: boolean;
  isPermanentResident: boolean | null; // Sungage doesn't require this. [xIsPermanentResident]
  mailingAddress?: IApplicantAddressBase;
  myMailingAddressIsDifferentThanCurrentAddress: boolean;
  employment: IApplicantEmploymentForFullApplication;
}

export interface IModifyApplicantFullApplicationPortal {
  primaryApplicant: IModifyApplicantForFullApplication;
  productLos: ProductLos;
}

export interface IPortalAccessibility {
  modifyApplication: boolean;
  modifyApplicant: boolean;
  addBorrower: boolean;
  modifyBorrower: boolean;
  uploadDocuments: boolean;
  resubmitApplication: boolean;
  issueLoanDocs: boolean;
  qcReview: boolean;
  enableNtpTab: boolean;
  enableFundingTab: boolean;
  enableInspectionsTab: boolean;
  enableLoanTab: boolean;
  editProjectId: boolean;
  downloadDocuments: boolean;
  convertApplication: boolean;
}

export type PortalAccessibilityKeys = keyof IPortalAccessibility;

export interface IEmailApplicationRequestPayload {
  productLos: ProductLos;
  lastName: string;
  firstName: string;
  email: string;
  mobileNumber: string;
  xprogramtype: number;
  loanAmount: number | null;
  projectId: string | null;
  applicationType: ApplicationType;
  moduleType: number | null;
  panelSize: number | null;
  panelCost: number | null;
  batterySize: number | null;
  batteryCost: number | null;
  panelQuantity: number | null;
  batteryType: number | null;
  batteryExtensionType: number | null;
  batteryQuantity: number | null;
  batteryExtensionQuantity: number | null;
  installationAddressLine: string | null;
  installationCity: string | null;
  installationState: string | null;
  installationZipCode: string | null;
}
