import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import CurrencyInput from "react-currency-input-field";

type FieldProps = {
  control: any;
  type?: string;
  name: any;
  placeholder?: string;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  inputProps?: any;
  children?: React.ReactNode;
  disableAbbreviations?: boolean;
  suffix?: string
  prefix?: string
  disableGroupSeparators?: boolean
};

export const UserFormCurrencyField = ({
  control,
  name,
  disabled = false,
  placeholder,
  type = "text",
  className,
  defaultValue = "",
  children,
  disableAbbreviations,
  suffix,
  prefix = "$",
  disableGroupSeparators
}: FieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value },
        fieldState: { error, isDirty },
      }) => (
        <>
          <CurrencyInput
            placeholder={placeholder}
            className={`form-control${!!error ? " is-invalid" : ""}`}
            decimalsLimit={2}
            value={value}
            prefix={prefix}
            onValueChange={onChange}
            disabled={disabled}
            disableAbbreviations={disableAbbreviations}
            suffix={suffix}
            disableGroupSeparators={disableGroupSeparators}
            name={name}
            id={name}
          />
          {children}
          {error && (
            <Form.Text id="passwordHelpBlock" className="text-danger" muted>
              {error?.message}
            </Form.Text>
          )}
        </>
      )}
    />
  );
};
