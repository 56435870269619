import { ConditionalRender } from "@shared/components/ConditionalRender";
import { IPortalApplication } from "@shared/interfaces/PortalApplicationTypes";

export const SystemInfo = ({
  requestDetail,
}: {
  requestDetail: IPortalApplication | undefined;
}) => (
  <div>
    <h4 className="font-bold-800">System Info</h4>
    <p>
      Panels:{" "}
      <ConditionalRender
        value={[
          requestDetail?.systemInfo?.panels?.quantity,
          requestDetail?.systemInfo?.panels?.model,
        ]
          .join(" ")
          .trim()}
      />
    </p>
    <p>
      Inverters:{" "}
      <ConditionalRender
        value={[
          requestDetail?.systemInfo?.inverters?.quantity,
          requestDetail?.systemInfo?.inverters?.model,
        ]
          .join(" ")
          .trim()}
      />
    </p>
    <p>
      Batteries:{" "}
      <ConditionalRender
        value={[
          requestDetail?.systemInfo?.batteries?.quantity,
          requestDetail?.systemInfo?.batteries?.model,
        ]
          .join(" ")
          .trim()}
      />
    </p>
    <p>
      Battery Expansions:{" "}
      <ConditionalRender
        value={[
          requestDetail?.systemInfo?.batteryExpansions?.quantity,
          requestDetail?.systemInfo?.batteryExpansions?.model,
        ]
          .join(" ")
          .trim()}
      />
    </p>
  </div>
);
