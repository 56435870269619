import { useMemo, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Control, Controller } from "react-hook-form";
import { DebounceInput } from "react-debounce-input";
import isFunction from "lodash/isFunction";
import clsx from "clsx";

import { Address, searchPlaces } from "@services/google.service";
import DropdownList, { DropdownListItem } from "./DropdownList";

type FieldProps = {
  control: Control;
  name: any;
  placeholder?: string;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  onSelect?: (address: Address) => void;
};

export const GoogleAutoComplete = ({
  control,
  name,
  placeholder,
  className,
  defaultValue = "",
  disabled,
  children,
  onSelect,
}: FieldProps) => {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const items = useMemo<DropdownListItem[]>(
    () =>
      addresses.map(({ address, id }) => ({
        title: address,
        value: id,
      })),
    [addresses]
  );
  const onSearch = (value: string = "") => {
    searchPlaces(value).then((results) => {
      setAddresses(results);
    });
  };
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value },
        fieldState: { error, isDirty },
      }) => (
        <>
          <InputGroup style={{ flexDirection: "column" }}>
            <DropdownList
              items={items}
              onClose={() => setAddresses([])}
              onSelect={(value: string) => {
                const address = addresses.find((item) => item.id === value);
                if (address && isFunction(onSelect)) {
                  onSelect(address);
                }
              }}
            >
              <DebounceInput
                minLength={2}
                className={clsx("form-control", className, {
                  "is-invalid": !!error,
                })}
                placeholder={placeholder}
                value={value}
                debounceTimeout={500}
                onChange={(event) => {
                  onChange(event.target.value)
                  onSearch(event.target.value)
                }}
                onBlur={() => {
                  // Set delay to allow for touch actions like click
                  setTimeout(() => {
                    setAddresses([]);
                  }, 200);
                }}
                onFocus={(event: any) => {
                  onSearch(event.target.value);
                }}
                disabled={disabled}
                style={{ width: "100%" }}
                name={name}
                id={name}
              />
            </DropdownList>
            {children}
            {error && (
              <Form.Text className="text-danger" muted>
                {error?.message}
              </Form.Text>
            )}
          </InputGroup>
        </>
      )}
    />
  );
};
